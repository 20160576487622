<template>
  <div>
   <v-container class="grid-list-xl mt-12">
     <v-row class="align-center justify-center">
      <v-card class="px-5" flat tile>
        <v-col cols="12" sm="6" md="12" class="mt-10 text-center">
        <h3>{{$t("terms.title")}}</h3>
      </v-col>
      <v-col cols="12" sm="6" md="12" class="mb-10 mt-5">
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt1")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt2")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt3")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt4")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt5")}}</div>
        <div class="fs-14 mt-4 fw-bold">{{$t("terms.noInvestmentAdvice")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.noInvestmentAdviceTxt")}}</div>
         <div class="fs-12 mt-2">{{$t("terms.noInvestmentAdviceTxt1")}}</div>
        <div class="fs-14 mt-4 fw-bold">{{$t("terms.investmentRisks")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.investmentRisksTxt")}}</div>
        <div class="fs-14 mt-4 fw-bold">{{$t("terms.TradingRisks")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.TradingRisksTxt")}}</div>
      </v-col>
      </v-card>
     </v-row>
     </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import config from '@/config.js'
    export default {
        data() {
        return {

        };
        },
        computed: {
        
        },
        created() {

        },
        mounted() {

        },
        watch: {

        },
        methods: {
        }
    }

</script>

